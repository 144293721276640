import React, { useCallback, useMemo, useState } from 'react';
import {
  CheckCircleOutlineOutlined,
  DoNotDisturbOnTotalSilenceOutlined,
} from '@mui/icons-material';
import { Control, UseFormWatch } from 'react-hook-form';
import { Grid, Tooltip } from '@mui/material';
import { debounce } from 'lodash';
import { FormInput, Loader } from 'components';
import { discounts, DiscountTypes } from 'api';
import { getCouponHelperText } from '../fields';

interface Props {
  control: Control<DiscountTypes.ItemCreateParams> | undefined;
  watch: UseFormWatch<DiscountTypes.ItemCreateParams>;
  initialData: DiscountTypes.ItemCreateParams;
}

const CouponCodeInput: React.FC<Props> = (props) => {
  const { control, watch, initialData } = props;

  const [isAlreadyExists, setIsAlreadyExists] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const couponCode = watch('coupon_code');
  const shopId = watch('shop_id');

  const isChangedCode = useMemo(
    () => initialData.coupon_code !== couponCode,
    [initialData.coupon_code, couponCode],
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleCheckCode = useCallback(
    debounce(async (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsLoading(true);

      const code = event.target.value;
      const codes = await discounts.autocompleteSearch({
        coupon_code: code,
        shop_id: shopId,
      });

      if (
        codes.some((r) => r.coupon_code.toUpperCase() === code.toUpperCase())
      ) {
        setIsAlreadyExists(true);
      } else {
        setIsAlreadyExists(false);
      }

      setIsLoading(false);
    }, 1000),
    [shopId],
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <FormInput
          control={control}
          name="coupon_code"
          inputType="input"
          label="Coupon code"
          error={isAlreadyExists}
          helperText={getCouponHelperText(couponCode)}
          InputProps={{
            endAdornment: isLoading ? (
              <Loader circularProgressProps={{ size: 20 }} />
            ) : (
              couponCode &&
              isChangedCode && (
                <Tooltip
                  title={`Coupon code "${couponCode}" ${
                    isAlreadyExists ? 'already exists' : 'free to use'
                  }`}
                >
                  {isAlreadyExists ? (
                    <DoNotDisturbOnTotalSilenceOutlined color="error" />
                  ) : (
                    <CheckCircleOutlineOutlined color="success" />
                  )}
                </Tooltip>
              )
            ),
          }}
          rules={{
            required: true,
            onChange: isChangedCode ? handleCheckCode : undefined,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default CouponCodeInput;
