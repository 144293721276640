import React, { useState } from 'react';
import { ButtonGroup } from '@mui/material';
import { QueryObserverResult } from '@tanstack/react-query';
import { useApiRequest } from 'hooks';
import { deals } from 'api';
import { useDataTableContext } from '../../context';
import { selectAllRows } from '../../context/actions';
import { ConfirmDialog } from '../../../ConfirmDialogs';
import CampaignAssign from './CampaignAssign';
import BookmarkAddAction from '../BookmarkAddAction';
import BookmarkRemoveAction from '../BookmarkRemoveAction';
import StatusAction from './StatusAction';
import DateAction from './DateAction';

interface Props {
  refetch: () => Promise<QueryObserverResult>;
  idKey: string;
}

const DealsActions = (props: Props): JSX.Element | null => {
  const { refetch, idKey } = props;

  const {
    state: { selectedItems },
    dispatch,
  } = useDataTableContext();

  const { requestFn, isLoading } = useApiRequest(deals.bulkUpdateStatus);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    setIsDeleting(true);
    const ids = selectedItems.map((s) => s[idKey]);

    await requestFn({
      args: {
        deal_ids: ids,
        is_deleted: true,
      },
      successMessage: `${ids.length} deals deleted`,
    });

    await refetch();
    dispatch(selectAllRows([]));
    setIsDeleting(false);
  };

  const disabled = selectedItems.length === 0;

  return (
    <ButtonGroup variant="outlined" color="inherit" disabled={disabled}>
      <BookmarkAddAction
        refetch={refetch}
        bookmarkType="deal_ids"
        idKey={idKey}
      />
      <BookmarkRemoveAction refetch={refetch} />
      <CampaignAssign type="assign" refetch={refetch} />
      <CampaignAssign type="remove" refetch={refetch} />
      <DateAction refetch={refetch} isDeleting={isDeleting} />
      <StatusAction refetch={refetch} isDeleting={isDeleting} />
      <ConfirmDialog
        name={`${selectedItems.length} deals`}
        buttonType="buttonGroup"
        onSubmit={handleDelete}
        isLoading={isLoading && isDeleting}
        disabled={disabled}
      />
    </ButtonGroup>
  );
};

export default DealsActions;
