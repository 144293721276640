import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Stack } from '@mui/material';
import { FormContainer } from 'components';
import { discounts, DiscountTypes } from 'api';
import { useApiRequest, useFiltersSearchParams } from 'hooks';
import { getDiffFields, getFieldsWithValues } from 'helpers/getFormFields';
import { appLinks } from 'routes/links';
import { useStoreContext } from 'store';
import OtherBlock from './components/OtherBlock';
import GeneralBlock from './components/GeneralBlock';
import DiscountBlock from './components/DiscountBlock';
import ProductsBlock from './components/ProductsBlock';
import { checkExternalDiscount, dataInitialSelectsOptions } from './fields';

interface Props {
  id: string | undefined;
  initialData: DiscountTypes.ItemCreateParams;
  initialSelectsOptions:
    | ReturnType<typeof dataInitialSelectsOptions>
    | undefined;
  drawer?: boolean;
  drawerTitle?: string;
}

const Form: React.FC<Props> = (props: Props): JSX.Element => {
  const { initialData, id, initialSelectsOptions, drawer, drawerTitle } = props;
  const navigate = useNavigate();
  const {
    state: { refetchTableData },
  } = useStoreContext();

  const {
    control,
    handleSubmit,
    watch,
    formState: { isDirty, isSubmitting },
    setValue,
  } = useForm<DiscountTypes.ItemCreateParams>({
    defaultValues: initialData,
  });

  const { updateFiltersSearchParams } = useFiltersSearchParams();

  const { requestFn } = useApiRequest(
    id ? (data) => discounts.update(id, data) : discounts.create,
  );

  const onSubmit = useCallback(
    async (data: DiscountTypes.ItemCreateParams) => {
      const values = id
        ? getDiffFields(initialData, data)
        : getFieldsWithValues(data);

      const res = await requestFn({
        args: values,
        successMessage: `Discount ${id ? 'updated' : 'created'}`,
        error: {
          code: 422,
          getError: (error) => error.message || 'Discount code already exists',
        },
      });

      if (res) {
        if (drawer) {
          updateFiltersSearchParams({
            discountId: undefined,
            drawerMode: undefined,
          });

          if (refetchTableData) {
            await refetchTableData();
          }
        } else {
          navigate(appLinks.discounts.details.as(res.discount_id), {
            replace: true,
            state: {
              from: id ? 'edit' : 'create',
            },
          });
        }
      }
    },
    [
      id,
      initialData,
      requestFn,
      drawer,
      updateFiltersSearchParams,
      refetchTableData,
      navigate,
    ],
  );

  const code = watch('coupon_code');
  const value = watch('value');

  const isDiscountDisabled = checkExternalDiscount(code).hasMatch && value > 0;

  return (
    <FormContainer
      variant={drawer ? 'drawer' : 'page'}
      drawerTitle={drawerTitle}
      pageLink={id ? appLinks.discounts.edit.as(id) : undefined}
      onSubmit={handleSubmit(onSubmit)}
      loading={isSubmitting}
      disabled={isDiscountDisabled || (!!id && !isDirty)}
    >
      <Stack spacing={2}>
        <GeneralBlock id={id} control={control} setValue={setValue} />
        <DiscountBlock
          control={control}
          watch={watch}
          setValue={setValue}
          initialData={initialData}
        />
        <ProductsBlock
          control={control}
          watch={watch}
          setValue={setValue}
          initialSelectsOptions={initialSelectsOptions}
        />
        <OtherBlock control={control} watch={watch} setValue={setValue} />
      </Stack>
    </FormContainer>
  );
};

export default Form;
