import { DealTypes, DiscountTypes } from 'api';
import { BrandsEnum } from 'api/types/Brands';
import { CountriesEnum } from 'api/types/Countries';
import { AutocompleteSearchItem } from 'api/types/common';

const initialModel: DiscountTypes.ItemCreateParams = {
  brand: BrandsEnum.avaAndMay,
  country_team: CountriesEnum.DE,
  strategy: DealTypes.StrategiesEnum.influencers,
  channel: DealTypes.ChannelInfluencerEnum.InstagramStories,
  discount_type: DiscountTypes.DiscountTypeEnum.Percentage,
  customer_eligibility: DiscountTypes.CustomerEligibilityEnum.Everyone,
  discount_amount_type: DiscountTypes.DiscountAmountTypeEnum.ProductDiscount,
  discount_details: DiscountTypes.DetailsEnum.PercentageDiscount,
  minimum_requirements: DiscountTypes.MinimumRequirementsEnum.MinimumQuantity,
  coupon_code: '',
  coupon_description: '',
  percentage_description: '',
  shop_id: '',
  end_date: undefined,
  start_date: '',
  value: '' as any, // should be "0" but removed to keep init value empty field
  minimum_value: '' as any, // should be "0" but removed to keep init value empty field
  usage_limit: null,
  applies_once_per_customer: false,
  applies_once_per_order: false,
  products: [],
  collections: [],
  customer_segments: [],
  customers_individual: [],
  free_gift_products: [],
  free_gift_product_categories: [],
};

const dataToInitialModel = (
  data: DiscountTypes.ItemData,
): DiscountTypes.ItemCreateParams => ({
  shop_id: data.shop_id.$oid,
  brand: data.brand,
  channel: data.channel,
  country_team: data.country_team,
  start_date: data.start_date,
  strategy: data.strategy,
  end_date: data.end_date,
  products: data.products || [],
  coupon_code: data.coupon_code,
  coupon_description: data.coupon_description,
  discount_type: data.discount_type,
  applies_to: data.applies_to,
  customer_eligibility: data.customer_eligibility,
  discount_amount_type: data.discount_amount_type,
  discount_details: data.discount_details,
  minimum_requirements: data.minimum_requirements,
  percentage_description: data.percentage_description,
  value: data.value,
  minimum_value: data.minimum_value,
  usage_limit: data.usage_limit,
  applies_once_per_customer: data.applies_once_per_customer,
  applies_once_per_order: data.applies_once_per_order,
  collections: data.collections || [],
  customer_segments: data.customer_segments || [],
  customers_individual: data.customers_individual || [],
  free_gift_products: data.free_gift_products || [],
  free_gift_product_categories: data.free_gift_product_categories || [],
});

const dataInitialSelectsOptions = (data: DiscountTypes.ItemData) => {
  const products: AutocompleteSearchItem[] = data.products.map((p) => ({
    _id: { $oid: p.id },
    name: p.title,
    image: p.image,
  }));
  const collections: AutocompleteSearchItem[] = data.collections.map((p) => ({
    _id: { $oid: p.id },
    name: p.title,
    image: p.image,
  }));
  const customerSegments: AutocompleteSearchItem[] = data.customer_segments.map(
    (p) => ({
      _id: { $oid: p.id },
      name: p.name,
    }),
  );
  const customersIndividual: AutocompleteSearchItem[] =
    data.customers_individual.map((p) => ({
      _id: { $oid: p.id },
      name: p.name,
    }));

  return {
    products,
    collections,
    customerSegments,
    customersIndividual,
  };
};

const checkExternalDiscount = (code: string) => {
  const prefixes = [
    'BIG',
    'FOR',
    'FUN',
    'SUP',
    'YEY',
    'ADV',
    'LOV',
    'OFF',
    'OMG',
    'NOV',
    'YAS',
    'TOP',
    'WOW',
    'SNO',
    'HAP',
    'BLA',
    'MIX',
    'FAL',
    'CBG',
    'DUO',
  ];

  const suffixes = ['JOY', 'OOL'];

  const codeUpper = code.toUpperCase();

  const matchedPrefixes = prefixes.filter((prefix) =>
    codeUpper.startsWith(prefix),
  );
  const matchedSuffixes = suffixes.filter((suffix) =>
    codeUpper.endsWith(suffix),
  );

  return {
    hasMatch: matchedPrefixes.length > 0 || matchedSuffixes.length > 0,
    hasBoth: matchedPrefixes.length > 0 && matchedSuffixes.length > 0,
    hasPrefix: matchedPrefixes.length > 0,
    hasSuffix: matchedSuffixes.length > 0,
    matchedPrefixes,
    matchedSuffixes,
  };
};

const getCouponHelperText = (couponCode: string) => {
  const discountInfo = checkExternalDiscount(couponCode);

  if (!discountInfo.hasMatch) {
    return '';
  }

  const prefixes = discountInfo.matchedPrefixes.join(', ');
  const suffixes = discountInfo.matchedSuffixes.join(', ');

  const prefixesText =
    prefixes.length > 1 ? `prefixes "${prefixes}"` : `prefix "${prefixes[0]}"`;

  const suffixesText =
    suffixes.length > 1 ? `suffixes "${suffixes}"` : `suffix "${suffixes[0]}"`;

  let message = 'The discount you entered is using ';

  if (discountInfo.hasBoth) {
    message += `both ${prefixesText} and ${suffixesText}`;
  } else {
    if (discountInfo.hasPrefix) {
      message += prefixesText;
    }

    if (discountInfo.hasSuffix) {
      message += suffixesText;
    }
  }

  return `${message}. Please make sure that discount code value is 0.`;
};

export {
  initialModel,
  dataToInitialModel,
  dataInitialSelectsOptions,
  checkExternalDiscount,
  getCouponHelperText,
};
