import React, { useMemo, useState } from 'react';
import { MenuItem, Stack, TextField, Typography } from '@mui/material';
import { DoneAllOutlined } from '@mui/icons-material';
import { QueryObserverResult } from '@tanstack/react-query';
import { useApiRequest } from 'hooks';
import { deals, DealTypes } from 'api';
import DEALS from 'constants/deals';
import { useDataTableContext } from '../../context';
import { selectAllRows } from '../../context/actions';
import { ConfirmDialog } from '../../../ConfirmDialogs';

interface Props {
  refetch: () => Promise<QueryObserverResult>;
  isDeleting: boolean;
}

const StatusAction = (props: Props): JSX.Element | null => {
  const { refetch, isDeleting } = props;

  const {
    state: { selectedItems },
    dispatch,
  } = useDataTableContext();

  const { requestFn, isLoading } = useApiRequest(deals.bulkUpdateStatus);
  const [newStatus, setNewStatus] = useState<DealTypes.StatusEnum>(
    DealTypes.StatusEnum.Posted,
  );

  const editableDeals = useMemo(
    () =>
      (selectedItems as DealTypes.ItemData[]).filter(
        (deal) =>
          deal.status !== DealTypes.StatusEnum.Posted &&
          deal.status !== DealTypes.StatusEnum.Finished,
      ),
    [selectedItems],
  );

  const handleChangeStatus = async () => {
    const ids = editableDeals.map((s) => s.deal_id);

    await requestFn({
      args: {
        deal_ids: ids,
        status: newStatus,
      },
      successMessage: `${ids.length} deals status changed to ${newStatus}`,
    });

    await refetch();
    dispatch(selectAllRows([]));
  };

  const disabled = selectedItems.length === 0;

  return (
    <ConfirmDialog
      name={`${selectedItems.length} deals`}
      buttonType="buttonGroup"
      onSubmit={handleChangeStatus}
      isLoading={isLoading && !isDeleting}
      disabled={disabled}
      icon={<DoneAllOutlined />}
      submitButtonText="Change status"
      buttonColor="primary"
      disabledSubmitButton={editableDeals.length === 0}
    >
      <Stack>
        {selectedItems.length - editableDeals.length > 0 && (
          <>
            <Typography gutterBottom variant="subtitle1">
              {`${selectedItems.length} deals selected`}
            </Typography>
            <Typography paragraph variant="subtitle2" color="error">{`${
              selectedItems.length - editableDeals.length
            } deals status is Posted or Finished and will not be changed`}</Typography>
          </>
        )}
        {editableDeals.length > 0 ? (
          <Typography
            gutterBottom
            variant="subtitle1"
          >{`Are You sure You want to change ${editableDeals.length} deals status?`}</Typography>
        ) : (
          <Typography gutterBottom variant="subtitle1">
            There is no deals available which status can be changed
          </Typography>
        )}
      </Stack>
      <TextField
        select
        value={newStatus}
        onChange={(e) => setNewStatus(e.target.value as DealTypes.StatusEnum)}
        sx={{ minWidth: 300 }}
      >
        {DEALS.STATUS_OPTIONS.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.value}
          </MenuItem>
        ))}
      </TextField>
    </ConfirmDialog>
  );
};

export default StatusAction;
