import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { Grid, Stack, Typography } from '@mui/material';
import { QueryObserverResult } from '@tanstack/react-query';
import { CalendarIcon, DatePicker } from '@mui/x-date-pickers';
import { useApiRequest } from 'hooks';
import { deals, DealTypes } from 'api';
import { getIsoUtcDate } from 'helpers/dayJsFormats';
import { useDataTableContext } from '../../context';
import { selectAllRows } from '../../context/actions';
import { ConfirmDialog } from '../../../ConfirmDialogs';

interface Props {
  refetch: () => Promise<QueryObserverResult>;
  isDeleting: boolean;
}

const DateAction = (props: Props): JSX.Element | null => {
  const { refetch, isDeleting } = props;

  const {
    state: { selectedItems },
    dispatch,
  } = useDataTableContext();

  const { requestFn, isLoading } = useApiRequest(deals.bulkUpdateStatus);
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);

  const editableDeals = useMemo(
    () =>
      (selectedItems as DealTypes.ItemData[]).filter(
        (deal) =>
          deal.status !== DealTypes.StatusEnum.Posted &&
          deal.status !== DealTypes.StatusEnum.Finished,
      ),
    [selectedItems],
  );

  const handleChangeStatus = async () => {
    const ids = editableDeals.map((s) => s.deal_id);

    await requestFn({
      args: {
        deal_ids: ids,
        start_date: startDate || undefined,
        end_date: endDate || undefined,
      },
      successMessage: `${ids.length} deals was updated`,
    });

    await refetch();
    dispatch(selectAllRows([]));
  };

  const disabled = selectedItems.length === 0;

  return (
    <ConfirmDialog
      name={`${selectedItems.length} deals`}
      buttonType="buttonGroup"
      onSubmit={handleChangeStatus}
      isLoading={isLoading && !isDeleting}
      disabled={disabled}
      icon={<CalendarIcon />}
      tooltip="Change start/end dates"
      submitButtonText="Change dates"
      buttonColor="primary"
      disabledSubmitButton={editableDeals.length === 0}
    >
      <Stack>
        {selectedItems.length - editableDeals.length > 0 && (
          <>
            <Typography gutterBottom variant="subtitle1">
              {`${selectedItems.length} deals selected`}
            </Typography>
            <Typography paragraph variant="subtitle2" color="error">{`${
              selectedItems.length - editableDeals.length
            } deals status is Posted or Finished and will not be changed`}</Typography>
          </>
        )}
        {editableDeals.length > 0 ? (
          <Typography
            paragraph
            variant="subtitle1"
          >{`Are You sure You want to change ${editableDeals.length} deals dates?`}</Typography>
        ) : (
          <Typography paragraph variant="subtitle1">
            There is no deals available which status can be changed
          </Typography>
        )}
      </Stack>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <DatePicker
            value={startDate ? dayjs.utc(startDate) : null}
            onChange={(value) => {
              setStartDate(value ? getIsoUtcDate(value) : '');
            }}
            closeOnSelect
            label="Start Date"
            slotProps={{
              textField: {
                variant: 'outlined',
                InputProps: {
                  notched: false,
                },
              },
            }}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            value={endDate ? dayjs.utc(endDate) : null}
            onChange={(value) => setEndDate(value ? getIsoUtcDate(value) : '')}
            closeOnSelect
            label="End Date"
            minDate={dayjs.utc(startDate)}
            slotProps={{
              textField: {
                variant: 'outlined',
                InputProps: {
                  notched: false,
                },
              },
            }}
          />
        </Grid>
      </Grid>
    </ConfirmDialog>
  );
};

export default DateAction;
